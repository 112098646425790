<template lang="pug">
popup(
  :status="true"
  @buttonClosePopup="close")

    template(slot="title")
      | {{ $t('MenuItems.matchPopup_Header') }}
      .PopupHeader
        .tab
          Button(
          size="medium"
          id="btn-menu-items-popup-tab-header-1"
          :class="{ 'isSelected' : activeTabIndex === 0 }",
          @click="tabChange(0)",
          type="button"
          ) {{ $t('MenuItems.matchPopup_Header_Recipe') }}
          Button(
          size="medium"
          id="btn-menu-items-popup-tab-header-2"
          :class="{ 'isSelected' : activeTabIndex === 1 }",
          @click="tabChange(1)",
          type="button"
          ) {{ $t('MenuItems.matchPopup_Header_StockItem') }}

    template(slot="content" v-if="activeTabIndex === 0")
      form.Form(
      data-vv-scope="formMatchRecipe"
      @submit.prevent="onSubmit('recipe')"
      id="form-match-recipe")
        .Form-item
          label.Form-item-label {{ $t('MenuItems.matchPopup_FormField_RecipeId') }}
          .control
            custom-search(
            component-name="menu-items-recipeId"
            name="recipe"
            ref="firstInput"
            id="firstInput"
            v-model="formMatchRecipe.recipe"
            :data-vv-as="$t('MenuItems.matchPopup_FormField_RecipeId')"
            data-vv-name="recipe"
            v-validate="'required'"
            :inputClass="{ 'is-danger': veeErrors.has('formMatchRecipe.recipe') }"
            :on-search="getRecipeOptions"
            :options="recipeSelectOptions"
            :loading="isSearchRecipeByName"
            label="name"
            :placeholder="$t('MenuItems.matchPopup_FormField_RecipeId_Placeholder')")
              template(slot="no-options") {{ $t('MenuItems.matchPopup_FormField_RecipeId_NoResults') }}
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('formMatchRecipe.recipe')"
          ) {{ veeErrors.first('formMatchRecipe.recipe') }}

    template(slot="content" v-else)
      form.Form(
      data-vv-scope="formMatchStock"
      @submit.prevent="onSubmit('stockItem')"
      id="form-match-stock")
        .Form-item
          label.Form-item-label {{ $t('MenuItems.matchPopup_FormField_StockItem') }}
          .control
            custom-search(
            component-name="menu-items-stockItem"
            name="stockItem"
            ref="firstInput"
            v-model="formMatchStock.stockItem"
            :data-vv-as="$t('MenuItems.matchPopup_FormField_StockItem')"
            data-vv-name="stockItem"
            v-validate="'required'"
            :inputClass="{ 'is-danger': veeErrors.has('formMatchStock.stockItem') }"
            :on-search="getStockOptions"
            :options="stockSelectOptions"
            :loading="isSearchStockItem"
            label="name"
            :placeholder="$t('MenuItems.matchPopup_FormField_StockItem_Placeholder')")
              template(slot="no-options") {{ $t('MenuItems.matchPopup_FormField_StockItem_NoResults') }}
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('formMatchStock.stockItem')"
          ) {{ veeErrors.first('formMatchStock.stockItem') }}

    template(slot="footer")

      Button(
      size="medium"
      variant="btn-remove-noborder"
      v-if="showButtonRemoveMatchedMenuItem"
      id="delete-match-stock-button"
      type="button"
      @click="onDeleteMatchedMenuItem")
        span(v-show="!isLoading") {{  $t('MenuItems.matchPopup_Delete_Button') }}
        Loading(theme="disable", v-show="isLoading")

      Button(
      primary
      size="medium"
      variant="full"
      v-if="activeTabIndex === 0"
      id="submit-match-recipe-button"
      type="submit"
      form="form-match-recipe"
      :disabled="isLoading || !formMatchRecipe.recipe")
        span(v-show="!isLoading") {{ $t('MenuItems.matchPopup_MatchButton') }}
        Loading(theme="disable", v-show="isLoading")

      Button(
      primary
      size="medium"
      variant="full"
      v-if="activeTabIndex === 1"
      id="submit-match-stock-button"
      type="submit"
      form="form-match-stock"
      :disabled="isLoading || !formMatchStock.stockItem")
        span(v-show="!isLoading") {{ $t('MenuItems.matchPopup_MatchButton') }}
        Loading(theme="disable", v-show="isLoading")

</template>

<script>
import { mapActions } from 'vuex'
import Popup from './index'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'MatchPopup',

  components: {
    Popup
  },

  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    method: {
      type: String,
      default: 'new'
    },
    showButtonRemoveMatchedMenuItem: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      formMatchRecipe: {
        recipe: null
      },
      formMatchStock: {
        stockItem: null
      },
      recipeSelectOptions: [],
      stockSelectOptions: [],
      activeTabIndex: 0
    }
  },

  async created () {
    if (this.method === 'update') {
      if (this.data[0].recipeHeaderId) {
        const recipe = await this.get_RECIPE_HEADER(this.data[0].recipeHeaderId)
        this.formMatchRecipe.recipe = recipe.data.recipeHeader
      } else if (this.data[0].stockItemId) {
        const stockItem = await this.getStockItem(this.data[0].stockItemId)
        this.formMatchStock.stockItem = stockItem.data.stockItem
        this.activeTabIndex = 1
      }
    }
  },

  mounted () {
    this.focusFirstInput()
  },

  computed: {
    isLoading () {
      return this.$wait.is(['onSubmitMatch', 'onDeleteMatchedMenuItem'])
    },
    isSearchRecipeByName () {
      return this.$wait.is('searchRecipeByName')
    },
    isSearchStockItem () {
      return this.$wait.is('searchStockItem')
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('MenuItems', [
      'matchMenuItems',
      'rematchMenuItems',
      'deleteMatchedMenuItems'
    ]),

    ...mapActions('Recipes', [
      'searchRecipeByName',
      'get_RECIPE_HEADER'
    ]),

    ...mapActions('Stock', [
      'searchStockItem',
      'getStockItem'
    ]),

    focusFirstInput () {
      this.$refs.firstInput.$refs.search.focus()
    },

    resetForm () {
      this.form = {
        recipe: null,
        stockItem: null
      }
    },

    close (whichOne) {
      this.$emit('close', whichOne)
    },

    tabChange (index) {
      this.activeTabIndex = index
      this.focusFirstInput()
    },

    onSubmit: vueWaitLoader(async function (itemType) {
      await this.$validator.validateAll('formMatchRecipe').then(async result => {
        if (!result) return

        const options = {
          items: this.data
        }

        if (itemType === 'recipe') {
          options.recipeHeaderId = this.formMatchRecipe.recipe.id
        } else if (itemType === 'stockItem') {
          options.stockItemId = this.formMatchStock.stockItem.id
        }

        const apiCall = () => (
          this.method === 'new'
            ? this.matchMenuItems(options)
            : this.rematchMenuItems(options)
        )

        await apiCall()
          .then((res) => {
            const matchTargetName = itemType === 'recipe'
              ? this.formMatchRecipe.recipe.name
              : this.formMatchStock.stockItem.name
            const message = this.$t('MenuItems.matchPopup_Notification_Success', {
              name1: this.data.map(item => item.name).join(', '),
              name2: matchTargetName
            })
            if (res) {
              this.notifyShow({ message })
            }
            this.$emit('submitForm')
            this.$emit('getList')
            this.close()
          })
      })
    }, 'onSubmitMatch'),

    onDeleteMatchedMenuItem: vueWaitLoader(async function () {
      const message = this.$t('MenuItems.matchPopup_Delete_Notification_Success', {
        name1: this.data.map(item => item.name).join(', ')
      })
      await this.deleteMatchedMenuItems(this.data)

      this.notifyShow({ message })
      this.$emit('removedMatchedItem')
      this.$emit('getList')
      this.close()
    }, 'onDeleteMatchedMenuItem'),

    getRecipeOptions (search) {
      this.searchRecipeByName({name: search, type: 1})
        .then(res => {
          if (res.status === 200) {
            this.recipeSelectOptions = res.data.recipeShortHeaderList
          }
        })
    },

    getStockOptions (search) {
      this.searchStockItem({text: search, types: [1, 2, 3]})
        .then(res => {
          if (res.status === 200) {
            this.stockSelectOptions = res.data.items
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>

  .PopupHeader {
    padding-bottom: 0;
    margin-top: 27px;

    .tab {
      display: flex;

      button {
        margin-right: 20px;
        font-size: 1.6rem;
        padding-bottom: 6px;
        border-bottom: 4px solid transparent;
        font-weight: $font-weight-normal;

        &.isSelected {
          pointer-events: none;
          color: $color-primary;
          border-bottom-color: $color-primary;
        }
      }
    }
  }

  .item-info {
    margin-bottom: 25px;

    .row {
      position: relative;
      margin-top: -1px;
      height: $finger-size;
      border: 1px solid $color-gray;
      background-color: white;
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-size: $font-size-small;
      padding-right: 10px;
      justify-content: space-between;

      &.multiple-values {
        height: auto;
        min-height: $finger-size;
        align-items: flex-start;
        padding: 18px 10px;
      }

      &:first-child {
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
      }

      &:last-child {
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }

      .col {
        &.title {
          font-weight: $font-weight-bold;
          color: $color-success;
        }

        &.value {
          color: $color-text-dark;
        }

        .item-info__name {
          &:not(:last-child) {
            &::after {
              content: '';
              display: inline-block;
              width: 4px;
              height: 4px;
              margin: 0 5px;
              vertical-align: middle;
              background: $color-gray;
              border-radius: 50%;
            }
          }
        }

      }

      &.multiple-values .col.value {
        padding-left: 10px;
        text-align: right;
      }
    }
  }
</style>
