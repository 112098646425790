import BaseClass from '@/utils/baseclass'

export class MenuItems extends BaseClass {
  addItem(item) {
    let newItem = new MenuItem(item)
    this.list.push(newItem)
  }

  updateItem(newItem) {
    let itemToUpdate = this.findItem(newItem.id)
    if (itemToUpdate) {
      itemToUpdate.id = newItem.id
      itemToUpdate.name = newItem.name
      itemToUpdate.menuItemGroupName = newItem.menuItemGroupName
      itemToUpdate.menuItemGroupId = newItem.menuItemGroupId
      itemToUpdate.revenueCenterName = newItem.revenueCenterName
      itemToUpdate.price = newItem.price
      itemToUpdate.storeGroupName = newItem.storeGroupName
    }
  }
}

class MenuItem {
  constructor(item) {
    this.id = item.id
    this.name = item.name
    this.menuItemGroupName = item.menuItemGroupName
    this.menuItemGroupId = item.menuItemGroupId
    this.revenueCenterName = item.revenueCenterName
    this.price = item.price
    this.storeGroupName = item.storeGroupName
  }
}

export class MatchedMenuItems extends BaseClass {
  addItem(item) {
    let newItem = new MatchedMenuItem(item)
    this.list.push(newItem)
  }

  updateItem(newItem) {
    let itemToUpdate = this.findItem(newItem.id)
    if (itemToUpdate) {
      itemToUpdate.id = newItem.id
      itemToUpdate.name = newItem.name
      itemToUpdate.revenueCenterName = newItem.revenueCenterName
      itemToUpdate.menuItemGroupName = newItem.menuItemGroupName
      itemToUpdate.matchedItemGroupName = newItem.matchedItemGroupName
      itemToUpdate.matchedItemName = newItem.matchedItemName
      itemToUpdate.costPercentage = newItem.costPercentage
      itemToUpdate.menuItemName = newItem.menuItemName
      itemToUpdate.type = newItem.type
      itemToUpdate.price = newItem.price
      itemToUpdate.cost = newItem.cost
      itemToUpdate.recipeHeaderId = newItem.recipeHeaderId
      itemToUpdate.recipeGroupId = newItem.recipeGroupId
      itemToUpdate.stockItemId = newItem.stockItemId
      itemToUpdate.stockItemGroupId = newItem.stockItemGroupId
      itemToUpdate.storeGroupName = newItem.storeGroupName
      itemToUpdate.storeName = newItem.storeName
    }
  }
}

class MatchedMenuItem {
  constructor(item) {
    this.id = item.id
    this.name = item.name
    this.revenueCenterName = item.revenueCenterName
    this.menuItemGroupName = item.menuItemGroupName
    this.matchedItemGroupName = item.matchedItemGroupName
    this.matchedItemName = item.matchedItemName
    this.menuItemName = item.menuItemName
    this.type = item.type
    this.costPercentage = item.costPercentage
    this.price = item.price
    this.cost = item.cost
    this.recipeHeaderId = item.recipeHeaderId
    this.recipeGroupId = item.recipeGroupId
    this.stockItemId = item.stockItemId
    this.stockItemGroupId = item.stockItemGroupId
    this.storeGroupName = item.storeGroupName
    this.menuItemIsActive = item.menuItemIsActive
    this.storeName = item.storeName
    this.rowTextClass = item.menuItemIsActive ? '' : 'TableViewPassiveItems'
  }
}

export class RVCList extends BaseClass {
  addItem(item) {
    let newItem = new RVC(item)
    this.list.push(newItem)
  }

  updateItem(newItem) {
    let itemToUpdate = this.findItem(newItem.id)
    if (itemToUpdate) {
      itemToUpdate.id = newItem.id
      itemToUpdate.name = newItem.name
      itemToUpdate.storeGroupName = newItem.storeGroupName
    }
  }
}

export class RVC {
  constructor(item) {
    this.id = item.id
    this.integrationId = item.integrationId
    this.name = item.name
    this.storeId = item.storeId
    this.storeName = item.storeName
    this.integrationType = item.integrationType
    this.integrationKey = item.integrationKey
    this.integrationProductId = item.integrationProductId
    this.integrationProductName = item.integrationProductName
    this.storeGroupId = item.storeGroupId
    this.storeGroupName = item.storeGroupName
    this.propertyName = item.propertyName
    this.isActive = item.isActive
  }
}

export class GroupList extends BaseClass {
  addItem(item) {
    let newItem = new MenuItemGroup(item)
    this.list.push(newItem)
  }

  updateItem(newItem) {
    let itemToUpdate = this.findItem(newItem.id)
    if (itemToUpdate) {
      itemToUpdate.id = newItem.id
      itemToUpdate.name = newItem.name
    }
  }
}

class MenuItemGroup {
  constructor(item) {
    this.id = item.id
    this.name = item.name
  }
}
