var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("businessMultiselect", {
    ref: "businessTransactionTypeFilter",
    attrs: {
      componentName: "multiselect-transaction-type-filter",
      label: _vm.$t("MenuItems.toolbar_Transaction_Type_Filter_Label"),
      popupHeader: _vm.$t("MenuItems.toolbar_Transaction_Type_Filter_Label"),
      multiselectItems: _vm.filter,
    },
    on: { submitFilter: _vm.submitFilter },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }