export default function getFields($t) {
  return [
    {
      name: 'integrationProductName',
      is_visible: true,
      headerTitle: false,
      get short_title() {
        return $t('RevenueCenters.tableColumn_integrationProductId')
      },
      get title() {
        return $t('RevenueCenters.tableColumn_integrationProductId')
      }
    },
    {
      name: 'propertyName',
      is_visible: true,
      headerSubTitle: true,
      is_sortable: true,
      sortingObject: {
        columnName: 'propertyName',
        sortType: null
      },
      get short_title() {
        return $t('RevenueCenters.tableColumn_propertyName')
      },
      get title() {
        return $t('RevenueCenters.tableColumn_propertyName')
      }
    },
    {
      name: 'name',
      is_visible: true,
      headerTitle: true,
      get short_title() {
        return $t('RevenueCenters.tableColumn_name')
      },
      get title() {
        return $t('RevenueCenters.tableColumn_name')
      }
    },
    {
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return $t('RevenueCenters.tableColumn_storeName')
      },
      get title() {
        return $t('RevenueCenters.tableColumn_storeName')
      }
    },
    {
      name: 'storeGroupName',
      is_visible: true,
      get short_title() {
        return $t('RevenueCenters.tableColumn_storeGroupName')
      },
      get title() {
        return $t('RevenueCenters.tableColumn_storeGroupName')
      }
    }
  ]
}
