import MenuItems from '../'
import MatchedMenuItems from '../matched'
import RevenueCenters from '../revenue-centers'
import SuspendSales from '../suspend-sales.vue'
import SuspendSalesHeaders from '../suspend-sales-header.vue'
import SuspendSalesDetail from '../suspend-sales-detail.vue'
import FormPopup from '../popup/revenue'

export default [
  {
    path: '/menu-items',
    name: 'MenuItems',
    component: MenuItems,
    meta: {
      main_menu: true,
      slug: 'menu-items',
      page: 'menu-items',
      feature_flag: 'MenuItems',
      category: 'Cost',
      permissionKey: 'SalesItemsMenu'
    }
  },
  {
    path: '/menu-items/matched',
    name: 'MatchedMenuItems',
    component: MatchedMenuItems,
    meta: {
      permissionKey: 'SalesItemsMenu',
      page: 'menu-items'
    }
  },
  {
    path: '/menu-items/revenue-centers',
    name: 'RevenueCenters',
    component: RevenueCenters,
    children: [
      {
        path: 'new',
        name: 'RVCNew',
        component: FormPopup
      },
      {
        path: ':id/edit',
        name: 'RVCEdit',
        component: FormPopup,
        props: {
          status: 'edit'
        }
      }
    ],
    meta: {
      permissionKey: 'SalesItemsMenu',
      page: 'menu-items'
    }
  },
  {
    path: '/menu-items/suspended-sales',
    name: 'SuspendedSales',
    component: SuspendSales,
    meta: {
      page: 'menu-items',
      permissionKey: 'SalesItemsMenu'
    },
    children: [
      {
        path: 'header',
        name: 'SuspendSalesHeaders',
        component: SuspendSalesHeaders
      },
      {
        path: 'detail/:errorType',
        name: 'SuspendSalesDetail',
        component: SuspendSalesDetail
      }
    ]
  }
]
