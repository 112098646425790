import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,

  state: {
    SuspendedSalesDetailList: [],
    SuspendedSalesHeaderList: [],
    TotalSuspendedAmount: 0,
    isSuspendedActive: false,
    Page: {
      number: 1,
      size: 20,
      total: 0
    }
  },

  getters: {
    suspendedSalesHeaderList: state => state.SuspendedSalesHeaderList,
    totalSuspendedAmount: state => state.TotalSuspendedAmount,
    suspendedSalesDetailList: state => state.SuspendedSalesDetailList,
    isEmptySuspendedSalesHeaderList: state => state.SuspendedSalesHeaderList.length === 0,
    isEmptySuspendedSalesDetailList: state => state.SuspendedSalesDetailList.length === 0,
    isSuspendedActive: state => state.isSuspendedActive,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    })
  },

  actions: {
    async updateActivationStatus({ commit, state, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateActivationStatus',
        methodName: 'updateActivationStatus',
        method: () => {
          return HTTP.put('SuspendTransaction/updateactivationstatus', payload)
        },
        success: result => result
      })
      return results
    },
    async getSuspendedSalesHeaderList({ commit, state, dispatch }, payload) {
        // startDate = "2019-01-08T07:45:28.554Z",
        // endDate = "2020-01-08T07:45:28.554Z",
        // showPassiveItems = true
        // storeGroupList = []
        // revenueCenterList = []
        // transactionStatusTypeParams = []
      const results = await requestHandler({
        title: 'getSuspendedSalesHeaderList',
        methodName: 'getSuspendedSalesHeaderList',
        method: () => {
          return HTTP.post('SuspendTransaction/headerlist', payload)
        },
        success: result => {
          const suspendHeaderList = result.data.suspendHeaderList
          commit('SET_SUSPENDED_SALES_HEADER_LIST', suspendHeaderList)
          return result
        }
      })
      return results
    },
    async getSuspendedSalesDetailList({ commit, state, dispatch }, {
      $t,
      page = 1,
      pageSize,
      errorType,
      startDate,
      endDate,
      storeGroupList,
      revenueCenterList,
      transactionStatusTypeParams,
      searchText = '',
      showPassiveItems
    }) {
      const PAGE_NUMBER = getPaginationNumber({ selectedPage: page, currentPage: state.Page.number })

      const results = await requestHandler({
        title: 'getSuspendedSalesDetailList',
        methodName: 'getSuspendedSalesDetailList',
        method: () => {
          return HTTP.post('SuspendTransaction/detaillist', {
            PageSize: pageSize,
            PageNumber: PAGE_NUMBER,
            errorType,
            startDate,
            endDate,
            storeGroupList,
            revenueCenterList,
            transactionStatusTypeParams,
            searchText,
            showPassiveItems
          })
        },
        success: result => {
          commit('SET_SUSPENDED_SALES_DETAIL_LIST', {
            $t,
            suspendDetailList: result.data.suspendDetailList
          })
          commit('SET_PAGINATION_INFO', result.data)
          return result
        }
      })
      return results
    },
    async getSuspendedStatus({ commit }) {
      const results = await requestHandler({
        title: 'getSuspendedStatus',
        methodName: 'getSuspendedStatus',
        method: () => {
          return HTTP.get('SuspendTransaction/getpostransactionstatus')
        },
        success: result => {
          commit('SET_SUSPENDED_STATUS', result.data.isActive)
          return result
        }
      })
      return results
    },
    async getTotalSuspendedAmount({ commit }) {
      const results = await requestHandler({
        title: 'getTotalSuspendedAmount',
        methodName: 'getTotalSuspendedAmount',
        method: () => {
          return HTTP.get('SuspendTransaction/totalsuspendamount')
        },
        success: result => {
          commit('SET_TOTAL_SUSPENDED_AMOUNT', result.data.totalSuspendAmount)
          return result
        }
      })
      return results
    },
    async updateSuspendedStatus({ commit, dispatch }, IsActive) {
      const results = await requestHandler({
        title: 'updateSuspendedStatus',
        methodName: 'updateSuspendedStatus',
        method: () => {
          return HTTP.put(`SuspendTransaction/updatepostransactionstatus?isActive=${IsActive}`)
        },
        success: result => {
          dispatch('getSuspendedStatus')
          return result
        }
      })
      return results
    }
  },

  mutations: {
    RESET(state) {
      state.SuspendedSalesDetailList = []
      state.SuspendedSalesHeaderList = []
    },

    SET_SUSPENDED_SALES_HEADER_LIST(state, list) {
      state.SuspendedSalesHeaderList = list
    },

    SET_PAGINATION_INFO(state, data) {
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },

    SET_SUSPENDED_SALES_DETAIL_LIST(state, {$t, suspendDetailList}) {
      suspendDetailList.forEach(item => {
        item.transactionTypeTranslation = $t(`MenuItems.transactionType_${item.transactionType}`)
        item.rowTextClass = item.menuItemIsActive ? '' : 'TableViewPassiveItems'
      })
      state.SuspendedSalesDetailList = suspendDetailList
    },
    SET_SUSPENDED_STATUS(state, status) {
      state.isSuspendedActive = status
    },
    SET_TOTAL_SUSPENDED_AMOUNT(state, amount) {
      state.TotalSuspendedAmount = amount
    }
  }
}
