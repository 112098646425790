<template lang="pug">
businessMultiselect(
    componentName='multiselect-transaction-type-filter'
    :label="$t('MenuItems.toolbar_Transaction_Type_Filter_Label')"
    :popupHeader="$t('MenuItems.toolbar_Transaction_Type_Filter_Label')"
    :multiselectItems="filter"
    ref="businessTransactionTypeFilter"
    @submitFilter="submitFilter"
  )
</template>

<script>
import businessMultiselect from '@/view/global/business-filters/business-multiselect'
const getList = ($t, transactionValues = [1, 2, 3, 4, 6]) => {
  return transactionValues.map(val => {
    return {
      name: $t(`MenuItems.transactionType_${val}`),
      id: val
    }
  })
}

export default {
  components: {
    businessMultiselect
  },

  data () {
    return {
      list: getList(this.$t),
      filter: []
    }
  },
  mounted () {
    this.filter = [...this.list]
  },

  methods: {
    clearStoreFilter (dontSubmit = true) {
      this.$refs.businessTransactionTypeFilter.clearAllFilter(dontSubmit)
    },

    submitFilter ({ list, dontSubmit }) {
      this.$emit('submitFilter', {list, dontSubmit})
    }
  }
}
</script>
