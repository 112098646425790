import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { MenuItems, MatchedMenuItems, RVCList, GroupList } from '../model'
import { Page, PageGetters, getPaginationNumber } from '@/utils/pagination'
import TargetStores from '../../MenuItemsSettings/model/targetStores'

export default {
  namespaced: true,

  state: {
    ImportedMenuItems: new MenuItems(),
    MatchedMenuItems: new MatchedMenuItems(),
    RVCList: new RVCList(),
    GroupList: new GroupList(),
    PermittedStoreGroupList: [],
    TargetStoreItems: new TargetStores(),
    SuspendedSalesAmount: 0,
    rvcListPagination: { ...Page },
    pageMenuItems: { ...Page },
    pageMatchedItems: { ...Page },
    pageTargetStores: { ...Page },
    pageHeaderLinks: [
      'MenuItems',
      'MatchedMenuItems',
      'RevenueCenters',
      'SuspendedSales'
    ]
  },

  getters: {
    pageHeaderLinks: state => state.pageHeaderLinks,
    importedMenuItems: state => state.ImportedMenuItems,
    isImportedMenuItemsEmpty: state => state.ImportedMenuItems.length === 0,
    matchedMenuItems: state => state.MatchedMenuItems,
    isMatchedMenuItemsEmpty: state => state.MatchedMenuItems.length === 0,
    RVCList: state => state.RVCList,
    isRVCListEmpty: state => state.RVCList.length === 0,
    groupList: state => state.GroupList,
    PermittedStoreGroupList: state => state.PermittedStoreGroupList,
    TargetStoreItems: state => state.TargetStoreItems,
    isTargetStoresEmpty: state => state.TargetStoreItems.length === 0,
    rvcListPagination: state => PageGetters({ state, stateName: 'rvcListPagination' }),
    pageMenuItems: state => PageGetters({ state, stateName: 'pageMenuItems' }),
    pageMatchedItems: state => PageGetters({ state, stateName: 'pageMatchedItems' }),
    pageTargetStores: state => PageGetters({ state, stateName: 'pageTargetStores' }),
    suspendedSalesAmount: state => state.SuspendedSalesAmount
  },

  actions: {
    async importMenuItems({commit}, storeGroupIds) {
      const results = await requestHandler({
        title: 'importMenuItems',
        methodName: 'importMenuItems',
        method: () => {
          return HTTP.post('Pos/sync', storeGroupIds)
        },
        success: result => result
      })
      return results
    },

    async getRVCList({ commit, state, dispatch }, params) {
      params.pageNumber = getPaginationNumber({ selectedPage: params.pageNumber || 1, currentPage: state.rvcListPagination.number })
      params.pageSize = params.pageSize || state.rvcListPagination.size
      const results = await requestHandler({
        title: 'getMenuItemsRVCList',
        methodName: 'getMenuItemsRVCList',
        method: () => {
          return HTTP.post('Pos/revenuecenter/all', params)
        },
        success: result => {
          commit('SET_RVC_LIST', result.data)
          return result
        }
      })
      return results
    },

    async getRVC({ commit, state, dispatch }, Id) {
      const results = await requestHandler({
        title: 'getMenuItemsRVC',
        methodName: 'getMenuItemsRVC',
        method: () => {
          return HTTP.get('Pos/revenuecenter', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async updateRVC({ commit, dispatch }, rvc) {
      const results = await requestHandler({
        title: 'editRVC',
        methodName: 'editRVC',
        method: () => {
          return HTTP.put('Pos/revenuecenter', rvc)
        },
        success: result => result
      })
      return results
    },

    async createRVC({ commit, dispatch }, rvc) {
      const results = await requestHandler({
        title: 'createRVC',
        methodName: 'createRVC',
        method: () => {
          return HTTP.post('Pos/revenuecenter', rvc)
        },
        success: result => result
      })
      return results
    },

    async mapRVCtoStore({ commit, dispatch }, { revenueCenterId, storeId }) {
      const results = await requestHandler({
        title: 'mapRVCtoStore',
        methodName: 'mapRVCtoStore',
        method: () => {
          return HTTP.put('Pos/revenuecenter/map', {
            revenueCenterId,
            storeId
          })
        },
        success: result => result
      })
      return results
    },

    async getGroupList({ commit, state, dispatch }) {
      const results = await requestHandler({
        title: 'getGroupList',
        methodName: 'getGroupList',
        method: () => {
          return HTTP.get('Pos/menuitemgroup/all')
        },
        success: result => {
          commit('SET_GROUP_LIST', result.data.itemList)
          return result
        }
      })
      return results
    },

    async getPermittedStoreGroups({ commit, state, dispatch }) {
      const results = await requestHandler({
        title: 'getPermittedStoreGroups',
        methodName: 'getPermittedStoreGroups',
        method: () => {
          return HTTP.get('StoreGroup/all/permitted')
        },
        success: result => {
          commit('SET_PERMITTED_STORE_GROUP_LIST', result.data.permittedStoreGroupList)
          return result
        }
      })
      return results
    },

    async filterImportedMenuItems({ commit, state, dispatch }, filters) {
      const PAGE_NUMBER = getPaginationNumber({ selectedPage: filters.page, currentPage: state.pageMenuItems.number })
      const payload = {
        pageSize: filters.pageSize,
        pageNumber: PAGE_NUMBER,
        revenueCenterIdList: filters.rvc.map(rvc => rvc.id),
        menuItemGroupIdList: filters.group.map(group => group.id),
        storeGroupIdList: filters.storeGroups.map(sg => sg.id),
        showCondiments: filters.showCondiments,
        text: filters.text
      }
      const results = await requestHandler({
        title: 'filterImportedMenuItems',
        methodName: 'filterImportedMenuItems',
        method: () => {
          return HTTP.post('Pos/menuitem/filter', payload)
        },
        success: result => {
          commit('SET_IMPORTED_MENU_ITEMS', result.data)
          return result
        }
      })
      return results
    },

    async matchMenuItems({ commit, state, dispatch }, { items, recipeHeaderId, stockItemId }) {
      const payload = {
        menuItemId: items.map(item => item.id),
        recipeHeaderId,
        stockItemId
      }

      const results = await requestHandler({
        title: 'matchMenuItems',
        methodName: 'matchMenuItems',
        method: () => {
          return HTTP.post('Pos/menuitem/matching', payload)
        },
        success: result => result
      })
      return results
    },

    async deleteMatchedMenuItems({ commit, state, dispatch }, items) {
      const payload = {
        ids: items.map(item => item.id)
      }
      const results = await requestHandler({
        title: 'deleteMatchedMenuItems',
        methodName: 'deleteMatchedMenuItems',
        method: () => {
          return HTTP.post('Pos/matchedmenuitem/delete', payload)
        },
        success: result => result
      })
      return results
    },

    async rematchMenuItems({ commit, state, dispatch }, { items, recipeHeaderId, stockItemId }) {
      const payload = {
        id: items[0].id,
        recipeHeaderId,
        stockItemId
      }
      const results = await requestHandler({
        title: 'matchMenuItems',
        methodName: 'matchMenuItems',
        method: () => {
          return HTTP.put('Pos/menuitem/matching', payload)
        },
        success: result => result
      })
      return results
    },

    async filterMatchedMenuItems({ commit, state, dispatch }, filters) {
      const PAGE_NUMBER = getPaginationNumber({ selectedPage: filters.page, currentPage: state.pageMatchedItems.number })

      const payload = {
        pageSize: filters.pageSize,
        pageNumber: PAGE_NUMBER,
        revenueCenterIdList: filters.rvc.map(rvc => rvc.id),
        menuItemGroupIdList: filters.group.map(group => group.id),
        storeGroupIdList: filters.storeGroups.map(sg => sg.id),
        showCondiments: filters.showCondiments,
        text: filters.text
      }

      const results = await requestHandler({
        title: 'filterMatchedMenuItems',
        methodName: 'filterMatchedMenuItems',
        method: () => {
          return HTTP.post('Pos/matchedmenuitem/filter', payload)
        },
        success: result => {
          commit('SET_MATCHED_MENU_ITEMS', result.data)
          return result
        }
      })
      return results
    },

    async retrySuspendedSales({ dispatch }, payload) {
      const results = await requestHandler({
        title: 'retrySuspendedSales',
        methodName: 'retrySuspendedSales',
        method: () => {
          return HTTP.post('SuspendTransaction/sale', payload)
        },
        success: result => result
      })
      return results
    },

    async revenuecenterCheckName({ dispatch }, params) {
      const results = await requestHandler({
        title: 'revenuecenterCheckName',
        methodName: 'revenuecenterCheckName',
        method: () => {
          return HTTP.get('Pos/revenuecenter/check/name', { params })
        },
        success: result => result
      })
      return results
    },

    async revenuecenterCheckNumber({ dispatch }, params) {
      const results = await requestHandler({
        title: 'revenuecenterCheckNumber',
        methodName: 'revenuecenterCheckNumber',
        method: () => {
          return HTTP.get('Pos/revenuecenter/check/id', { params })
        },
        success: result => result
      })
      return results
    },

    async posMenuItemGroups({ commit }, payload) {
      const results = await requestHandler({
        title: 'posMenuItemGroups',
        methodName: 'posMenuItemGroups',
        method: () => {
          return HTTP.post('Pos/menuitemgroup/filter', payload)
        },
        success: result => result
      })
      return results
    },

    async getTargetStore({ state, commit }, payloadData) {
      const pageNumber = getPaginationNumber({ selectedPage: payloadData.pageNumber, currentPage: state.pageTargetStores.number })
      const payload = {
        ...payloadData,
        pageNumber: pageNumber
      }

      const results = await requestHandler({
        title: 'getTargetStore',
        methodName: 'getTargetStore',
        method: () => {
          return HTTP.post('Pos/menuitem/targetstore', payload)
        },
        success: result => {
          commit('SET_TARGET_STORES', result.data)
          return result
        }
      })
      return results
    },

    async getFilteredTargetStore({ commit }, payload) {
      const results = await requestHandler({
        title: 'getFilteredTargetStore',
        methodName: 'getFilteredTargetStore',
        method: () => {
          return HTTP.post('Pos/menuitem/filter/targetstore', payload)
        },
        success: result => result
      })
      return results
    },

    async editTargetStores({ commit }, payload) {
      const results = await requestHandler({
        title: 'editTargetStores',
        methodName: 'editTargetStores',
        method: () => {
          return HTTP.put('Pos/menuitem/targetstore', payload)
        },
        success: result => result
      })
      return results
    },

    async postan_gelir_aktar({ dispatch }, formData) {
      const results = await requestHandler({
        title: 'postan-gelir-aktar',
        methodName: 'postan-gelir-aktar',
        method: () => {
          return HTTP.post('Sales/import', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    RESET(state) {
      state.ImportedMenuItems = new MenuItems()
      state.MatchedMenuItems = new MatchedMenuItems()
      state.RVCList = new RVCList()
      state.GroupList = new GroupList()
      state.TargetStoreItems = new TargetStores()
    },

    SET_IMPORTED_MENU_ITEMS(state, menuItems) {
      state.ImportedMenuItems.addItems(menuItems.itemList)
      state.pageMenuItems.number = menuItems.pageNumber
      state.pageMenuItems.size = menuItems.pageSize
      state.pageMenuItems.total = menuItems.totalCount
    },

    SET_MATCHED_MENU_ITEMS(state, menuItems) {
      state.MatchedMenuItems.addItems(menuItems.itemList)
      state.pageMatchedItems.number = menuItems.pageNumber
      state.pageMatchedItems.size = menuItems.pageSize
      state.pageMatchedItems.total = menuItems.totalCount
    },

    SET_RVC_LIST(state, revenueCenterList) {
      state.RVCList.addItems(revenueCenterList.itemList)
      state.rvcListPagination.number = revenueCenterList.pageNumber
      state.RVCList.list.forEach(item => {
        item.rowTextClass = item.isActive ? '' : 'TableViewPassiveItemsNoBorder'
      })
      if (revenueCenterList.pageSize <= 100) state.rvcListPagination.size = revenueCenterList.pageSize
      state.rvcListPagination.total = revenueCenterList.totalCount
    },

    SET_GROUP_LIST(state, groupList) {
      state.GroupList.addItems(groupList)
    },

    SET_PERMITTED_STORE_GROUP_LIST(state, storeGroupList) {
      state.PermittedStoreGroupList = storeGroupList
    },

    SET_SUSPENDED_SALES_AMOUNT(state, totalAmount) {
      state.SuspendedSalesAmount = totalAmount
    },

    SET_TARGET_STORES(state, data) {
      state.TargetStoreItems.addItems(data.itemList)
      state.pageTargetStores.number = data.pageNumber
      state.pageTargetStores.size = data.pageSize
      state.pageTargetStores.total = data.totalCount
    }
  }
}
