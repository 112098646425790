<template lang="pug">
transition(name="slide")
    .Popup(v-if="status")
      .Popup-overlay(@click="close('closePopup')")
      .Popup-body

        .Popup-header
          .content
            h3.Popup-header-title
              slot(name="title")
            slot(name="detail")
          Button.Popup-header-close(
          size="small"
          variant="icon"
          id="btn-menu-items-popup-close"
          type="button"
          iconName="icon-popup-close"
          iconClass="icon-popup-close"
          @click="close('closePopup')")
          
        .Popup-content
          slot(name="content")
        .Popup-footer
          slot(name="footer")
</template>

<script>
export default {
  name: 'Popup',
  props: {
    status: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close (whichOne) {
      this.$emit('buttonClosePopup', whichOne)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
