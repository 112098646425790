<template lang="pug">
popup(
  :status="true"
  @buttonClosePopup="close")

    template(slot="title" v-if="isEdit")
      | {{ $t('RevenueCenters.formPopupEdit_Header') }}
    template(slot="title" v-else)
      | {{ $t('RevenueCenters.formPopup_Header') }}

    template(slot="content")
      form.Form(
      @submit.prevent="onSubmit"
      id="form-revenue-centers"
      data-vv-scope="formRevenueCenter")
        div.Form-item.required
          label.Form-item-label {{ $t('RevenueCenters.formPopup_Field_Code') }}
          .control
            customTextInput(
              id="input-revenue-centers-form-field-id"
              ref="firstField"
              v-model="form.integrationId"
              v-validate="integrationIdValidation"
              name="integrationId"
              :data-vv-as="$t('RevenueCenters.formPopup_Field_Code')"
              :error="veeErrors.has('formRevenueCenter.integrationId')"
              :disabled="isEdit"
              :isDelayInput="true"
            )

            Button(
            variant="icon loading"  
            id="btn-revenue-popup-loading-Id"
            type="button",
            tabindex="-1",
            v-show="isPendingId")
              Loading(theme="disable")
            Button(
            variant="icon check"  
            id="btn-recipes-popup-ok-Id"
            type="button",
            tabindex="-1",
            iconName="icon-check",
            iconClass="icon-check",
            v-show="isIdValid")
            showValidateError(
              v-show="veeErrors.has('formRevenueCenter.integrationId')"
              :errorName="veeErrors.first('formRevenueCenter.integrationId')"
            )

        div.Form-item.required
          label.Form-item-label {{ $t('RevenueCenters.formPopup_Field_Name') }}
          .control
            customTextInput(
              id="input-revenue-centers-form-field-name"
              v-model="form.name"
              v-validate="nameValidation"
              name="name"
              :data-vv-as="$t('RevenueCenters.formPopup_Field_Name')"
              :error="veeErrors.has('formRevenueCenter.name')"
              :disabled="isTypeSimpraPos"
              :isDelayInput = "true"
            )

            Button(
            variant="icon loading"
            id="btn-rvccennter-popup-loading-name"
            type="button",
            tabindex="-1",
            v-show="isPendingName")
              Loading(theme="disable")
            Button(
            variant="icon check"
            id="btn-popup-rvccennter-ok-name"
            type="button",
            tabindex="-1",
            iconName="icon-check",
            iconClass="icon-check",
            v-show="isNameValid")
            showValidateError(
              v-show="veeErrors.has('formRevenueCenter.name')"
              :errorName="veeErrors.first('formRevenueCenter.name')"
            )
        
        .Form-item.required
          label.Form-item-label {{ $t('RevenueCenters.popupForm_Field_Store') }}
          .control.form-item-select
            custom-search(
              component-name="revenue-center-popup-store-select"
              name="storeName",
              v-model="form.store",
              v-validate="'required|is_not:-1'"
              :data-vv-as ="$t('RevenueCenters.popupForm_Field_Store')"
              :error="veeErrors.has('storeName')"
              :on-search="getOptionsStore",
              :options="searchStoreList",
              label="name",
              :searchAll="true"
              :withDebounce="false"
              :placeholder="$t('Despatches.detail_Popup_FormField_StoreName_Placeholder')"
              :loading="isSearchStoreLists"
              @input="selectStore"
              @resetOptions="resetOptions"
            )

            showValidateError(
              v-show="veeErrors.has('storeName')"
              :errorName="veeErrors.first('storeName')"
            )

        div.Form-item(v-if="storeGroup.name")
          .Form-item-info-multiple
            .Form-item-info-multiple-row
              .text {{ $t('StoreGroups.popupForm_Name') }}
              .value {{ storeGroup.name }}

    template(slot="footer")
      template(v-if="isEdit")
        Button(
        size="large"
        primary
        variant="full" 
        id="submit-revenue-centers-button"
        type="submit"
        form="form-revenue-centers"
        :disabled="isLoading")
          span(v-show="!isLoading") {{ $t('RevenueCenters.formPopupEdit_SubmitButton') }}
          Loading(theme="disable", v-show="isLoading")
      template(v-else)
        Button(
        primary
        size="large"
        variant="full"
        id="submit-revenue-centers-button"
        type="submit"
        form="form-revenue-centers"
        :disabled="isLoading")
          span(v-show="!isLoading") {{ $t('RevenueCenters.formPopup_SubmitButton') }}
          Loading(theme="disable", v-show="isLoading")

</template>

<script>
import { mapFields } from 'vee-validate'
import { mapGetters, mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import Popup from './index'

export default {
  name: 'RevenueCentersFormPopup',

  components: {
    Popup
  },

  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      storeSelectOptions: [],
      form: {
        integrationId: null,
        name: null,
        store: {
          id: '-1',
          name: null
        },
        integrationKey: null,
        integrationType: null
      },
      storeGroup: {}
    }
  },

  mounted () {
    this.getOptionsStore('')
    if (this.isEdit) {
      this.init()
    }
    this.focusFirstInput()
  },

  computed: {
    ...mapGetters('Stores', [
      'searchStoreList'
    ]),

    ...mapGetters('MenuItems', {
      revenueCenterList: 'RVCList'
    }),

    ...mapFields({
      flagsName: 'formRevenueCenter.name',
      flagsId: 'formRevenueCenter.integrationId'
    }),

    isSearchStoreLists () {
      return this.$wait.is('fetchStoreByName')
    },

    nameValidation () {
      const url = this.isEdit ? `Pos/revenuecenter/check/name?Id=${this.$route.params.id}` : 'Pos/revenuecenter/check/name'
      return `required|max:128|very_singularity:${url},Name`
    },

    integrationIdValidation () {
      const url = this.isEdit ? `Pos/revenuecenter/check/id?Id=${this.$route.params.id}` : 'Pos/revenuecenter/check/id'
      return `required|max:128|very_singularity:${url},IntegrationId`
    },

    isLoading () {
      return this.$wait.is(['mapRVCtoStore', 'editRVC', 'createRVC', 'onSubmitRevenueAdd'])
    },

    isEdit () {
      return this.status === 'edit'
    },

    isTypeSimpraPos () {
      return this.form.integrationProductId === '8a4a410b-9fb6-4ae7-a816-95de3a13b57b'
    },

    isPendingName () {
      return this.flagsName.pending
    },

    isNameValid () {
      const isValid = this.flagsName.valid && !this.isPendingName
      if (!this.flagsName.required) {
        return isValid && !!this.form.name
      } else {
        return this.flagsName.valid &&
                 this.form.name !== '' &&
                 this.form.name !== null &&
                 !this.isPendingName
      }
    },

    isPendingId () {
      return this.flagsId.pending
    },

    isIdValid () {
      if (this.flagsId.required) {
        return this.flagsId.valid &&
                 !this.isPendingId
      } else {
        return this.flagsId.valid &&
                 this.form.integrationId !== '' &&
                 this.form.integrationId !== null &&
                 !this.isPendingId
      }
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('MenuItems', [
      'getRVC',
      'createRVC',
      'updateRVC',
      'mapRVCtoStore',
      'revenuecenterCheckNumber'
    ]),

    ...mapActions('Stores', ['fetchStoreByName']),

    ...mapActions('StoreGroups', [
      'fetchStoreGroupByStoreName'
    ]),

    getOptionsStore (search) {
      this.fetchStoreByName({name: search})
    },

    resetOptions () {
      this.getOptionsStore('')
    },

    selectStore (data) {
      if (data.length !== 0) {
        this.form.store.id = data.id
        this.form.store.name = data.name
      }
      this.getStoreGroupByStoreName()
    },

    async init () {
      const formData = this.$route.params.item || (await this.getRVC(this.$route.params.id)).data.item
      this.form = {...formData}
      this.form.store = {id: formData.storeId, name: formData.storeName}
      const isStoreExist = this.searchStoreList.find(i => i.id === this.form.store.id)
      if (!isStoreExist) this.form.store.id = '-1'

      this.form.store.id ? this.getStoreGroupByStoreName() : this.form.store.id = '-1'
    },

    async getStoreGroupByStoreName () {
      if (this.form.store.id !== '-1') {
        const res = await this.fetchStoreGroupByStoreName(this.form.store.id)
        this.storeGroup = res.data.storeGroup
      } else {
        this.storeGroup = {}
      }
    },
    focusFirstInput () {
      this.$refs.firstField.focusCustomInput()
    },

    resetForm () {
      this.form = {
        integrationId: null,
        name: null,
        store: {id: '-1', name: null},
        integrationKey: null,
        integrationType: null
      }
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    submit () {
      this.$emit('submit')
      this.close()
    },

    onSubmit: vueWaitLoader(async function () {
      await this.$validator.validateAll('formRevenueCenter').then(async result => {
        // form error
        if (!result) return

        if (this.isEdit) await this.submitEdit()
        else await this.submitNew()
      })
    }, 'onSubmitRevenueAdd'),

    async submitNew () {
      const revenueCenter = Object.keys(this.form).reduce((form, key) => {
        // Revert dropdowns with value -1 to null
        return Object.assign({}, form, {
          [key]: this.form[key] === '-1' ? null : this.form[key]
        })
      }, {})

      const payload = {
        integrationId: revenueCenter.integrationId,
        integrationKey: revenueCenter.integrationKey,
        integrationType: revenueCenter.integrationType,
        name: revenueCenter.name,
        storeId: revenueCenter.store.id
      }
      await this.createRVC(payload).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Created', { name: revenueCenter.name })
          this.notifyShow({ message })
          this.submit()
        }
      })
    },

    async submitEdit () {
      let submitRevenueCenter
      if (this.isTypeSimpraPos) {
        submitRevenueCenter = this.mapRVCtoStore({
          revenueCenterId: this.$route.params.id,
          storeId: this.form.store.id === '-1' ? null : this.form.store.id
        })
      } else {
        submitRevenueCenter = this.updateRVC({
          id: this.form.id,
          name: this.form.name,
          storeId: this.form.store.id === '-1' ? null : this.form.store.id
        })
      }
      await submitRevenueCenter.then(res => {
        if (res) {
          const message = this.$t('Global.notification_Updated', { name: this.form.name })
          this.notifyShow({ message })
          this.submit()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  .PopupHeader {
    padding-bottom: 0;
    margin-top: 27px;

    .tab {
      display: flex;

      button {
        margin-right: 20px;
        font-size: 1.6rem;
        padding-bottom: 6px;
        border-bottom: 4px solid transparent;
        font-weight: $font-weight-normal;

        &.isSelected {
          pointer-events: none;
          color: $color-primary;
          border-bottom-color: $color-primary;
        }
      }
    }
  }

  .Form-item-info-multiple {
  display: flex;
  align-items: center;
  font-size: $font-size-small;
  flex-wrap: wrap;
  margin-top: 17px;

  &-row {
    display: flex;
    justify-content: space-between;
    border: solid $color-gray 1px;
    width: 100%;
    padding: 17px 10px;
  }

  .text {
    color: $color-success;
    font-weight: bold;
    margin-right: 6px;
  }

  .value {
    font-weight: $font-weight-bold;
  }
}

  .item-info {
    margin-bottom: 25px;

    .row {
      position: relative;
      margin-top: -1px;
      height: $finger-size;
      border: 1px solid $color-gray;
      background-color: white;
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-size: $font-size-small;
      padding-right: 10px;
      justify-content: space-between;

      &.multiple-values {
        height: auto;
        min-height: $finger-size;
        align-items: flex-start;
        padding: 18px 10px;
      }

      &:first-child {
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
      }

      &:last-child {
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }

      .col {
        &.title {
          font-weight: $font-weight-bold;
          color: $color-success;
        }

        &.value {
          color: $color-text-dark;
        }

        .item-info__name {
          &:not(:last-child) {
            &::after {
              content: '';
              display: inline-block;
              width: 4px;
              height: 4px;
              margin: 0 5px;
              vertical-align: middle;
              background: $color-gray;
              border-radius: 50%;
            }
          }
        }

      }

      &.multiple-values .col.value {
        padding-left: 10px;
        text-align: right;
      }
    }
  }
</style>
